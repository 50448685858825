define([
    'lodash',
    'color',
    'coreUtils',
    'utils/fonts/uploadedFontsUtils'
], function (_,
             color,
             coreUtils,
             uploadedFontsUtils
) {
    'use strict';
    const colorParser = coreUtils.colorParser;
    const COLOR_PARAM_KEY_PREFIX = 'param_color_',
        NUMBER_PARAM_KEY_PREFIX = 'param_number_',
        BOOLEAN_PARAM_KEY_PREFIX = 'param_boolean_',
        FONT_PARAM_KEY_PREFIX = 'param_font_';

    const getFontsKeyToNameMap = function () {
        return {
            'Title': 'font_0',
            'Menu': 'font_1',
            'Page-title': 'font_2',
            'Heading-XL': 'font_3',
            'Heading-L': 'font_4',
            'Heading-M': 'font_5',
            'Heading-S': 'font_6',
            'Body-L': 'font_7',
            'Body-M': 'font_8',
            'Body-S': 'font_9',
            'Body-XS': 'font_10'
        };
    };

    const getNameToFontsKeyMap = function () {
        const fontsKeyToNameMap = getFontsKeyToNameMap();
        return _.invert(fontsKeyToNameMap);
    };

    const createFontObject = function (currFontString, fontName) {
        const fontObj = coreUtils.fontUtils.parseFontStr(currFontString);
        const fontWithFallbacks = coreUtils.fontUtils.getFontFamilyWithFallbacks(fontObj.family);
        return {
            editorKey: fontName,
            lineHeight: fontObj.lineHeight,
            style: fontObj.style,
            weight: fontObj.weight,
            size: fontObj.size,
            fontFamily: fontObj.family.toLowerCase(),
            value: `font:${[fontObj.style, fontObj.variant, fontObj.weight, `${fontObj.size}/${fontObj.lineHeight}`, fontWithFallbacks].join(' ')};`
        };
    };
    const rgbToHex = function (rgbAsString) {
        const rgbAsArray = rgbAsString.split(',');

        return color({
            r: rgbAsArray[0],
            g: rgbAsArray[1],
            b: rgbAsArray[2]
        }).hexString();
    };

    const getUploadFontFaces = function (allFonts, allTheme, serviceTopology, styleId) {
        const siteTextPresets = _.assign(getStylesFonts(styleId, allTheme), getTextPresets(allFonts));
        const usedUploadedFonts = _(siteTextPresets)
            .map(function (val) {
                if (_.isString(val)) {
                    val = JSON.parse(val);
                }
                return val.family || val.fontFamily;
            })
            .uniq()
            .value();

        return uploadedFontsUtils.getUploadedFontFaceStyles(usedUploadedFonts, serviceTopology.mediaRootUrl);
    };

    const getAllGoogleFontsUrl = function (allFonts, allTheme, styleId, documentType, characterSets) {
        return getGoogleFontsCssUrl(_.assign(getStylesFonts(styleId, allTheme), getTextPresets(allFonts)), documentType, characterSets);
    };
    const getGoogleFontsCssUrl = function (siteTextPresets, documentType, characterSets) {
        const fontsObj = {};
        _.forEach(siteTextPresets, function (val) {
            if (typeof val === 'string') {
                val = JSON.parse(val);
            }
            fontsObj[val.family || val.fontFamily] = 1;
        });
        const fontUrl = coreUtils.fontUtils.getFontsUrlWithParams(fontsObj, documentType, characterSets);

        // In case there are no google fonts, return an empty url
        if (_.includes(fontUrl, 'family=null')) {
            return '';
        }
        return fontUrl;
    };
    const getStylesFonts = function (styleId, siteTheme) {
        const fontsDic = {};
        const stylesProps = getAppStyleProperties(styleId, siteTheme);

        _.forEach(stylesProps, function (val, key) {
            if (key.match(FONT_PARAM_KEY_PREFIX)) {
                fontsDic[key.replace(FONT_PARAM_KEY_PREFIX, '')] = val;
            }
        });
        return fontsDic;
    };

    const getAppStyleProperties = function (styleId, siteThemes) {
        const myTheme = siteThemes[styleId];
        if (!myTheme) {
            return null;
        }
        const styleData = myTheme.style && myTheme.style.properties;
        if (!styleData) {
            return null;
        }
        return styleData;
    };
    const getColorParamFromStyle = function (prop, styles, isExtraParam, theme) { // eslint-disable-line complexity
        const val = styles[prop];
        let selectedColor;

        if (val && !_.isString(val) && val.value) { //ui-lib
            return {themeName: undefined, value: val.value.cssColor || val.value.color.value || val.value.rgba};
        }
        if (!isExtraParam && val && _.isString(val) && coreUtils.stringUtils.startsWith(val, 'color_')) {
            let colorValue = colorParser.getColorValue(theme, val);
            colorValue = _.includes(colorValue, ',') ? rgbToHex(colorValue) : colorValue;
            const themeVal = color(colorValue);
            if (styles.hasOwnProperty(`alpha-${prop}`) && styles[`alpha-${prop}`] !== 1) {
                selectedColor = `rgba(${themeVal.values.rgb.join(',')},${styles[`alpha-${prop}`]})`;
            } else {
                selectedColor = themeVal.hexString();
            }
            return {themeName: val, value: selectedColor};
        } else if (!isExtraParam) {
            return {themeName: undefined, value: val};
        }
        return {themeName: undefined, value: undefined};
    };

    const getValueForWixParams = function (allThemes, wixParams, callback) {
        const result = _.reduce(wixParams, function (output, value, prop) {
            let key;
            const isExtraParam = coreUtils.stringUtils.startsWith(prop, 'alpha-');

            if (isExtraParam) {
                return output;
            } else if (prop.match(COLOR_PARAM_KEY_PREFIX)) {
                key = getStyleKey(COLOR_PARAM_KEY_PREFIX, prop);
                output[key] = _.get(getColorParamFromStyle(prop, wixParams, isExtraParam, allThemes), 'value');
                return output;
            } else if (prop.match(NUMBER_PARAM_KEY_PREFIX)) {
                key = getStyleKey(NUMBER_PARAM_KEY_PREFIX, prop);
                output[key] = +value;
                return output;
            } else if (prop.match(BOOLEAN_PARAM_KEY_PREFIX)) {
                key = getStyleKey(BOOLEAN_PARAM_KEY_PREFIX, prop);
                output[key] = getBoolValue(value);
                return output;
            } else if (prop.match(FONT_PARAM_KEY_PREFIX)) {
                key = getStyleKey(FONT_PARAM_KEY_PREFIX, prop);
                output[key] = _.get(getFontObject(value, allThemes), 'value');
                return output;
            }
        }, {});

        callback(result);
    };

    const getBoolValue = function (value) {
        if (_.isBoolean(value)) {
            return value;
        }

        return value !== 'false';
    };

    const getStyleKey = function (prefix, key) {
        return `style.${key.replace(prefix, '')}`;
    };

    const setValueForStoredParam = function (prop, stylesProps, stylesForSDK, allTheme) {
        //skips extra params
        const allThemes = allTheme.THEME_DATA;
        const isExtraParam = coreUtils.stringUtils.startsWith(prop, 'alpha-');
        if (stylesProps.hasOwnProperty(prop) && !isExtraParam) {
            if (prop.match(COLOR_PARAM_KEY_PREFIX)) {
                stylesForSDK.colors[prop.replace(COLOR_PARAM_KEY_PREFIX, '')] = getColorParamFromStyle(prop, stylesProps, isExtraParam, allThemes);
            } else if (prop.match(NUMBER_PARAM_KEY_PREFIX)) {
                stylesForSDK.numbers[prop.replace(NUMBER_PARAM_KEY_PREFIX, '')] = +stylesProps[prop];
            } else if (prop.match(BOOLEAN_PARAM_KEY_PREFIX)) {
                stylesForSDK.booleans[prop.replace(BOOLEAN_PARAM_KEY_PREFIX, '')] = getBoolValue(stylesProps[prop]);
            } else if (prop.match(FONT_PARAM_KEY_PREFIX)) {
                stylesForSDK.fonts[prop.replace(FONT_PARAM_KEY_PREFIX, '')] = getFontObject(stylesProps[prop], allThemes);
            }
        }
    };

    const getFontObject = function (font, allThemes) { // eslint-disable-line complexity
        const savedFont = typeof font === 'object' ? font.value : JSON.parse(font);
        if (savedFont.fontStyleParam && savedFont.preset === 'Custom') {
            updateCustomSavedFontValue(savedFont);
        } else if (savedFont.fontStyleParam && savedFont.theme) {
            updatePresetSelectedFromNewUILib(savedFont);
        } else if (savedFont.fontStyleParam) {
            updatePresetSavedFontValue(savedFont, allThemes.font);
        } else if (savedFont.fontParam && savedFont.preset === 'None') {
            updateSavedFontValueFromNewUILib(savedFont);
        } else if (savedFont.fontParam) {
            updateSavedFontValue(savedFont);
        }

        return savedFont;
    };

    const updateCustomSavedFontValue = function (savedFont) {
        const fontWithFallbacks = coreUtils.fontUtils.getFontFamilyWithFallbacks(savedFont.family);
        const size = `${savedFont.size}px`;
        const lineHeight = `${Math.floor(savedFont.size * 1.25)}px`; //font size +20%
        const style = savedFont.style.italic ? 'italic' : 'normal';
        const weight = savedFont.style.bold ? 'bold' : 'normal';
        const variant = 'normal';
        let value = '';
        value += `font:${[style, variant, weight, `${size}/${lineHeight}`, fontWithFallbacks].join(' ')};`;
        value += savedFont.style.underline ? 'text-decoration:underline;' : '';
        savedFont.value = value; // eslint-disable-line santa/no-side-effects
    };

    const updatePresetSavedFontValue = function (savedFont, allFonts) {
        const allFontsDef = getTextPresets(allFonts);
        const themeFont = allFontsDef[savedFont.preset];

        savedFont.value = themeFont.value; // eslint-disable-line santa/no-side-effects
        savedFont.size = parseInt(themeFont.size, 10); // eslint-disable-line santa/no-side-effects
        savedFont.family = themeFont.fontFamily; // eslint-disable-line santa/no-side-effects
        savedFont.style.bold = themeFont.weight === 'bold';
        savedFont.style.italic = themeFont.style === 'italic';
        savedFont.style.underline = false;
    };

    const updatePresetSelectedFromNewUILib = function (savedFont) {
        const fontWithFallbacks = coreUtils.fontUtils.getFontFamilyWithFallbacks(savedFont.family);
        const themeFont = coreUtils.fontUtils.parseFontStr(savedFont.preset);

        savedFont.value = `font:${[themeFont.style, themeFont.variant, themeFont.weight, `${themeFont.size}/${themeFont.lineHeight}`, fontWithFallbacks].join(' ')};`; // eslint-disable-line santa/no-side-effects
        savedFont.size = parseInt(themeFont.size, 10); // eslint-disable-line santa/no-side-effects
        savedFont.family = themeFont.family.toLowerCase(); // eslint-disable-line santa/no-side-effects
        savedFont.style.bold = themeFont.weight === 'bold';
        savedFont.style.italic = themeFont.style === 'italic';
        savedFont.style.underline = false;
    };

    const updateSavedFontValueFromNewUILib = function (savedFont) {
        const fontWithFallbacks = coreUtils.fontUtils.getFontFamilyWithFallbacks(savedFont.family);
        const style = savedFont.style.italic ? 'italic' : 'normal';
        const weight = savedFont.style.bold ? 'bold' : 'normal';
        let value = '';
        value += `font-family:${fontWithFallbacks};font-style:${style};font-weight:${weight};`;
        value += savedFont.style.underline ? 'text-decoration:underline;' : '';
        savedFont.value = value; // eslint-disable-line santa/no-side-effects
    };


    const updateSavedFontValue = function (savedFont) {
        savedFont.family = savedFont.value; // eslint-disable-line santa/no-side-effects
        savedFont.value = buildFontValue(savedFont); // eslint-disable-line santa/no-side-effects
        savedFont.size = 0; // eslint-disable-line santa/no-side-effects
        savedFont.style = {// eslint-disable-line santa/no-side-effects
            bold: false,
            italic: false,
            underline: false
        };
    };
    const buildFontValue = function (font) {
        let value = `font-family:${font.cssFontFamily || font.value};`;
        // Escape double quote for FF/IE support
        value = value.replace(/''/g, "'");

        return value;
    };

    // SE-21244: remove protocol so it works with both http and https TPAs
    const getWixStoredFontsCssUrls = function (serviceTopology, documentType, isViewerMode) {
        let cssUrls = coreUtils.fontUtils.getWixStoredFontsCssUrls(serviceTopology, documentType);
        if (!isViewerMode) {
            cssUrls = _.invokeMap(cssUrls, String.prototype.replace, /^https?\:/, '');
        }
        return cssUrls;
    };

    const getAllThemeForSDK = (siteData, pageId, styleId) => {
        const compStyle = siteData.getDataByQuery(styleId, pageId, siteData.dataTypes.THEME);
        const THEME_DATA = siteData.getDataByQuery('THEME_DATA', 'masterPage', siteData.dataTypes.THEME);
        return {
            [styleId]: compStyle,
            THEME_DATA
        };
    };

    const getStylesForSDK = function (siteThemes, styleId, includeCssUrls, serviceTopology, documentType, characterSets, isViewerMode) {
        const mainTheme = siteThemes.THEME_DATA;
        const stylesForSDK = {
            colors: {},
            numbers: {},
            booleans: {},
            fonts: {},
            googleFontsCssUrl: getAllGoogleFontsUrl(mainTheme.font, siteThemes, styleId, documentType, characterSets),
            uploadFontFaces: getUploadFontFaces(mainTheme.font, siteThemes, serviceTopology, styleId)
        };
        const stylesProps = getAppStyleProperties(styleId, siteThemes);
        if (!stylesProps) {
            return stylesForSDK;
        }

        _.forEach(stylesProps, function (value, prop) {
            setValueForStoredParam(prop, stylesProps, stylesForSDK, siteThemes);
        });

        if (includeCssUrls) {
            return _.merge(stylesForSDK, {
                cssUrls: getWixStoredFontsCssUrls(serviceTopology, documentType, isViewerMode)
            });
        }

        return stylesForSDK;
    };

    const getTextPresets = function (allFonts) {
        let index = 0;
        return _.reduce(getFontsKeyToNameMap(), function (result, value, key) {
            result[key] = createFontObject(allFonts[index++], value);
            return result;
        }, {});
    };

    const getSiteColors = function (allColors) {
        return _.reduce(allColors, function (res, item, index) {
            if (index >= 1 && index <= 5 || index >= 11 && index <= 35) { // eslint-disable-line no-mixed-operators
                res.push({
                    name: `color_${index}`,
                    value: _.includes(item, ',') ? rgbToHex(item) : item
                });
            }
            return res;
        }, []);
    };

    const filterLegacyFonts = function (fontsByLang) {
        _.forEach(fontsByLang, function (lang) {
            lang.fonts = _.reject(lang.fonts, {permissions: 'legacy'}); // eslint-disable-line santa/no-side-effects
        });
        return fontsByLang;
    };

    
    const getStyleDataToPassIntoApp = function (styleId, allTheme, santaBase, documentType, characterSets, serviceTopology, isViewerMode) {
        const allThemes = allTheme.THEME_DATA;
        const imageSprintUrl = coreUtils.media.getMediaUrl(serviceTopology, 'editorUI/fonts.v11.png');

        const fonsMetaWithoutLegacyFonts = filterLegacyFonts(coreUtils.fontUtils.getCurrentSelectablefontsWithParams(documentType, characterSets));
        const cssUrls = getWixStoredFontsCssUrls(serviceTopology, documentType, isViewerMode);

        return {
            fonts: {
                cssUrls,
                imageSpriteUrl: imageSprintUrl,
                fontsMeta: fonsMetaWithoutLegacyFonts
            },
            siteTextPresets: getTextPresets(allThemes.font),
            siteColors: getSiteColors(allThemes.color),
            style: styleId ? getStylesForSDK(allTheme, styleId, false, serviceTopology, documentType, characterSets, isViewerMode) : {}
        };
    };
    return {
        getTextPresets,
        getSiteColors,
        getAllThemeForSDK,
        getStylesForSDK,
        getStyleDataToPassIntoApp,
        getNameToFontsKeyMap,
        getValueForWixParams

    };
});
