define(['lodash', 'mobx'], function (_, mobx) {
    'use strict';

    return function transform(siteData, path, value) {
        if (path.length > 1) {
            return value;
        }

        const transformedRenderFlags = _.defaults({
            componentPreviewStates: mobx.observable.map(value.componentPreviewStates)
        }, value);

        return mobx.observable(transformedRenderFlags);
    };
});
