define(['lodash', 'coreUtils', 'platformInit', 'utils/routers/onRoutingResponse'], function (_, coreUtils, platformInit, onRoutingResponse) {
    'use strict';

    const ajaxLibrary = coreUtils.ajaxLibrary;

    const MEMBERS_APP_DEFINITION_ID = '14cc59bc-f0b7-15b8-e1c7-89ce41d0e0c9';

    const REQUEST_TYPES = {
        PAGES: 'PAGES',
        SITE_MAP: 'SITE_MAP',
        SITE_MAP_COUNT: 'SITE_MAP_COUNT'
    };

    const REQUEST_ROUTES = {
        PAGES: '/pages',
        SITE_MAP: '/sitemap',
        SITE_MAP_COUNT: '/sitemapCount'
    };

    const getRoutersPathConfig = instanceId => ({
        editor: {
            'wix-code': `https://${instanceId}.dev.wix-code.com/routers/custom`,
            'dataBinding': `https://${instanceId}.dev.wix-code.com/routers/data-binding`
        },
        viewer: {
            'wix-code': '/_api/wix-code-public-dispatcher/routers/custom',
            'dataBinding': '/_api/wix-code-public-dispatcher/routers/data-binding'
        }
    });

    function getWixCodeRouterPath({appDefinitionId, instanceId, isEditor, siteData}) {
        const resolvedRoutersPaths = getRoutersPathConfig(instanceId);
        const routersPathsByViewMode = isEditor ? resolvedRoutersPaths.editor : resolvedRoutersPaths.viewer;
        const routerPath = routersPathsByViewMode[appDefinitionId];

        if (!isEditor) {
            const baseUrl = _.trimEnd(siteData.getExternalBaseUrl(), '/');
            return `${baseUrl}${routerPath}`;
        }

        return routerPath;
    }

    function getSantaMembersRouterPath(currentUrl, isEditor, routerPath, isWixSite, isPremiumDomain) {
        if (!isEditor) {
            //request to members must contain the site name when in a user site
            let siteName;
            if (isWixSite) {
                //wix site url different - the first to slugs are the site name
                // www.wix.com/app-builder/home/...  ==> siteName=app-builder/home
                siteName = currentUrl.path.replace(/.*?\/([^\/]*\/[^\/]*).*/, '$1');
            } else if (isPremiumDomain) {
                return routerPath;
            } else {
                //freemium site
                siteName = currentUrl.path.replace(/.*?\/([^\/]*).*/, '$1');
            }

            return `/${siteName}${routerPath}`;
        }

        return routerPath;
    }

    function getRouterPath(currentUrl, clientSpecMap, appDefinitionId, isEditor, isWixSite, isPremiumDomain) {
        const clientSpecMapForApplication = _.find(clientSpecMap, {appDefinitionId});
        const routerPath = clientSpecMapForApplication.appFields.platform.routerServiceUrl;

        if (appDefinitionId === MEMBERS_APP_DEFINITION_ID) {
            return getSantaMembersRouterPath(currentUrl, isEditor, routerPath, isWixSite, isPremiumDomain);
        }

        return routerPath;
    }

    function getBackendRequestUrl(routerPath, currentUrl, requestType) {
        const domainForRouter = currentUrl.host;

        const requestRoute = REQUEST_ROUTES[requestType];

        if (/^\//.test(routerPath)) {
            return `${currentUrl.protocol}//${domainForRouter}${routerPath}${requestRoute}`;
        }
        return `${routerPath}${requestRoute}`;
    }

    function getUrlParams(clientSpecMap, appDefinitionId, isEditor) {
        const viewMode = `viewMode=${isEditor ? 'editor' : 'site'}`;
        const params = [viewMode];

        const appSpec = platformInit.specMapUtils.getAppSpec(clientSpecMap, appDefinitionId);
        if (appSpec) {
            params.push(`instance=${appSpec.instance}`);
        }

        return `?${params.join('&')}`;
    }

    function getBackendRequestObject(pageRoles, suffix, routerDefinition, publicBaseUrl, formFactor, queryParams) {
        const routerConfig = _.isString(routerDefinition.config) ? JSON.parse(routerDefinition.config) : routerDefinition.config;
        const routerSuffix = `/${suffix}`.replace(/^\/\//, '/');
        let fullUrl = `${publicBaseUrl}/${routerDefinition.prefix}${routerSuffix}`;
        if (queryParams) {
            fullUrl = `${fullUrl}?${queryParams}`;
        }
        //todo - temporarly send the /<prefix>/<suffix> in the fullUrl parameter, just until the router backend will be fixed to use the right parameters
        const result = {
            fullUrl,
            routerPrefix: `/${routerDefinition.prefix}`,
            routerSuffix,
            requestInfo: {
                formFactor
            },
            config: routerConfig,
            pageRoles
        };

        return result;
    }

    function getCSRFTokenValue() {
        return coreUtils.cookieUtils.getCookie('XSRF-TOKEN');
    }

    function getWixCodeUrlParams(instance, gridAppId, isEditor) {
        const viewMode = `viewMode=${isEditor ? 'editor' : 'site'}`;
        const _instance = `instance=${instance}`;
        const _gridAppId = `gridAppId=${gridAppId}`;
        const params = [viewMode, _instance, _gridAppId];

        return `?${params.join('&')}`;
    }

    function getWixCodeAjaxParameters({paramObj, requestType, siteData}) {
        const {
            currentUrl,
            clientSpecMap,
            isEditor,
            routerDefinition: {appDefinitionId},
            rendererModel_wixCodeModel_appData_codeAppId
        } = paramObj;

        const {instanceId, instance} = platformInit.specMapUtils.getAppSpec(clientSpecMap);
        const routerPath = getWixCodeRouterPath({appDefinitionId, instanceId, isEditor, siteData});
        const urlParams = getWixCodeUrlParams(instance, rendererModel_wixCodeModel_appData_codeAppId, isEditor);
        const url = getBackendRequestUrl(routerPath, currentUrl, requestType) + urlParams;

        const successCallback = routerResponse => {
            if (_.get(routerResponse, 'result._elementoryError')) {
                coreUtils.logWixCodeConsoleMessage(`Internal server error:${routerResponse.result.stack}`);
            }
        };

        const extraAjaxParams = {
            xhrFields: {
                withCredentials: true
            },
            crossDomain: true
        };

        return {
            url,
            successCallback,
            extraAjaxParams
        };
    }

    function getAjaxParametersForRouterRequest({paramObj, requestType, siteData}) {
        const appDefinitionId = paramObj.routerDefinition.appDefinitionId;

        if (/(wix-code|dataBinding)/.test(appDefinitionId)) {
            return getWixCodeAjaxParameters({paramObj, requestType, siteData});
        }

        const {
            currentUrl,
            clientSpecMap,
            isEditor,
            isWixSite,
            isPremiumDomain
        } = paramObj;

        const routerPath = getRouterPath(currentUrl, clientSpecMap, appDefinitionId, isEditor, isWixSite, isPremiumDomain);
        const urlParams = getUrlParams(clientSpecMap, appDefinitionId, isEditor);
        const url = getBackendRequestUrl(routerPath, currentUrl, requestType) + urlParams;

        return {
            url
        };
    }

    function getFromBackEnd(paramObj, requestType, onSuccess, onError, siteData) {
        const {url, successCallback, extraAjaxParams} = getAjaxParametersForRouterRequest({paramObj, requestType, siteData});

        const ajaxParams = {
            type: 'POST',
            dataType: 'json',
            url,
            data: getBackendRequestObject(paramObj.pageRoles, paramObj.suffix, paramObj.routerDefinition, paramObj.publicBaseUrl, paramObj.formFactor, paramObj.queryParams),
            success(routerResponse) {
                if (successCallback) {
                    successCallback(routerResponse);
                }

                onSuccess(routerResponse);
            },
            error: onError
        };

        _.assign(ajaxParams, extraAjaxParams);

        ajaxParams.headers = {
            'X-XSRF-TOKEN': getCSRFTokenValue()
        };

        ajaxLibrary.ajax(ajaxParams);
    }

    function makeTpaParamObjFromPs(ps, pageId, pageData) {
        const page = pageData.getPageData(ps, pageId);
        const clientSpecMap = ps.siteAPI.getClientSpecMap();
        const appData = _.get(clientSpecMap, _.get(page, 'tpaApplicationId'), {});
        const tpaRouterDefinition = {
            appDefinitionId: _.get(appData, 'appDefinitionId'),
            pages: {[_.get(page, 'tpaPageId')]: pageId},
            prefix: _.get(page, 'pageUriSEO')
        };
        return makeParamObjFromPs(ps, tpaRouterDefinition, pageData);
    }

    function makeParamObjFromPs(ps, routerDefinition, pageData) {
        const pages = _.map(pageData.getPagesList(ps, true), function (_pageId) {
            return {
                pageId: _pageId,
                title: pageData.getPageData(ps, _pageId).title
            };
        });
        const paramObj = {
            isEditor: true,
            currentUrl: ps.siteAPI.getCurrentUrl(),
            clientSpecMap: ps.siteAPI.getClientSpecMap(),
            rendererModel_wixCodeModel_appData_codeAppId: ps.dal.get(ps.pointers.wixCode.getGridAppId()),
            routerDefinition,
            suffix: '',
            pageRoles: _.reduce(routerDefinition.pages, function (result, value, key) {
                result[key] = {
                    id: value,
                    title: _.find(pages, {pageId: value}).title
                };
                return result;
            }, {}),
            publicBaseUrl: ps.siteAPI.getPublicBaseUrl()
        };
        return paramObj;
    }

    function makeParamObjFromSiteData(siteData, routerDefinition, navInfo) {
        const query = _.assign({}, siteData.getQueryParams(), navInfo.queryParams || {});
        const queryParamsStr = coreUtils.urlUtils.toQueryString(query);
        const paramObj = {
            primaryPageId: siteData.getPrimaryPageId(),
            isPreview: !!siteData.documentServicesModel,
            isEditorPreview: siteData.renderFlags.componentViewMode === 'preview' && !!siteData.documentServicesModel,
            isEditor: !siteData.isViewerMode(),
            currentUrl: siteData.currentUrl,
            clientSpecMap: siteData.getClientSpecMap(),
            rendererModel_wixCodeModel_appData_codeAppId: _.get(siteData, 'rendererModel.wixCodeModel.appData.codeAppId'),
            routerDefinition,
            suffix: navInfo.innerRoute,
            queryParams: queryParamsStr,
            pageRoles: _.reduce(routerDefinition.pages, function (result, value, key) {
                const title = _.chain(siteData)
                    .get('rendererModel.pageList.pages')
                    .find({pageId: value})
                    .get('title')
                    .value();
                result[key] = {
                    id: value,
                    title: title || _.get(siteData.getDataByQuery(value), 'title')
                };
                return result;
            }, {}),
            publicBaseUrl: siteData.getPublicBaseUrl(),
            formFactor: siteData.isMobileView() || siteData.isMobileDevice() ? 'mobile' : 'desktop',
            isWixSite: siteData.isWixSite(),
            isPremiumDomain: siteData.isPremiumDomain()
        };
        return paramObj;
    }

    function cleanPrefixesFromSiteMap(siteMapEntries, prefix) {
        const reg = new RegExp(`.*?\/${prefix}`);
        _.forEach(siteMapEntries, function (entry) {
            if (entry && entry.url) {
                entry.url = entry.url.replace(reg, '');
                if (entry.url.charAt(0) === '/' && entry.url.length > 1) {
                    entry.url = entry.url.substring(1);
                }
            }
        });
    }

    function getInnerRoutesSiteMap(paramObj, onSuccess, onError, siteData) {
        getInnerRoutesSiteMapByRequestType(paramObj, REQUEST_TYPES.SITE_MAP, onSuccess, onError, siteData);
    }

    function getInnerRoutesSiteMapCount(paramObj, onSuccess, onError, siteData) {
        getInnerRoutesSiteMapByRequestType(paramObj, REQUEST_TYPES.SITE_MAP_COUNT, onSuccess, onError, siteData);
    }

    function getInnerRoutesSiteMapByRequestType(paramObj, requestType, onSuccess, onError, siteData) {
        function onSiteMapResponse(routerResponse) {
            if (routerResponse.exception) {
                onError(routerResponse.result);
                return;
            }
            const siteMap = routerResponse.result;
            cleanPrefixesFromSiteMap(siteMap, paramObj.routerDefinition.prefix);
            onSuccess(routerResponse.result);
        }

        getFromBackEnd(paramObj, requestType, onSiteMapResponse, onError, siteData);
    }

    function getPage(paramObj, onSuccess, onError, siteData) {
        const _onPageResponse = _.partial(onRoutingResponse.checkResponse, paramObj.routerDefinition, paramObj.suffix, paramObj.isPreview, paramObj.isEditorPreview, paramObj.primaryPageId, paramObj.publicBaseUrl, onSuccess);
        getFromBackEnd(paramObj, REQUEST_TYPES.PAGES, _onPageResponse, onError, siteData);
    }

    return {
        getPage,
        getInnerRoutesSiteMap,
        getInnerRoutesSiteMapCount,
        makeParamObjFromPs,
        makeTpaParamObjFromPs,
        makeParamObjFromSiteData
    };
});
